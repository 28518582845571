import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';
import { generateUrlQueryParams } from "@utils/generateUrlQueryParams";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  locationsAll: [],
  locations: {
    total: 0,
    limit: 0,
    start: 0,
    results: []
  },
  states: [],
  categories: [],
  config: {},
  userInfo: {},
};

const slice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    setLocations: (state, action) => {
      state.locations = action.payload
      state.isLoading = false;
    },

    setAllLocations: (state, action) => {
      state.locationsAll = action.payload
      state.isLoading = false;
    },

    addNewLocation: (state, action) => {
      state.locations = {
        ...state.locations,
        results: [
          ...state.locations.results,
          action.payload
        ]
      }
      state.isLoading = false;
    },

    addNewLocationsFromCSV: (state, action) => {
      state.locations = {
        ...state.locations,
        results: [
          ...state.locations.results,
          ...action.payload
        ]
      }
      state.isLoading = false;
    },

    setStates: (state, action) => {
      state.states = action.payload
      state.isLoading = false;
    },

    setConfig: (state, action) => {
      state.config = action.payload
      state.isLoading = false;
    },

    setCategories: (state, action) => {
      state.categories = action.payload
      state.isLoading = false;
    },

    setUserInfo: (state, action) => {
      state.userInfo = action.payload
      state.isLoading = false;
    },
    deleteLocations: (state, action) => {
      state.isLoading = false;
    },
    addDroppedSignUp: (state, action) => {
      state.addDroppedSignUp = action.payload
      state.isLoading = false;
    },


  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  setLocations, setAllLocations, addNewLocationsFromCSV, setConfig, setCategories,
  addNewLocation, setStates, hasError, startLoading, setUserInfo,
} = slice.actions;

// ----------------------------------------------------------------------

export function getLocationsList(
  {
    limit = 100,
    start = 0,
    search = ''
  }
) {
  return async () => {
    dispatch(startLoading());
    try {
      const url = generateUrlQueryParams('/locations', {
        limit,
        start,
        search,
      })
      const response = await axios.get(url);
      dispatch(setLocations(response.data))
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function getAllLocations() {
  return async () => {
    dispatch(startLoading());
    try {
      const response = await axios.get(`/locations/getAll`);
      dispatch(setAllLocations(response.data.results))
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}



export function getStatesList() {
  return async () => {
    dispatch(startLoading());
    try {
      const response = await axios.get('/locations/states');
      dispatch(setStates(response.data.states))
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function addLocation(formData) {
  return async () => {
    dispatch(startLoading());
    try {
      const response = await axios.post(`/locations`, formData);
      dispatch(addNewLocation(response.data))
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function addLocationCSV(formData, addOnlyValid = false) {
  return async () => {
    dispatch(startLoading());
    try {
      const response = await axios.post(`/locations/file${addOnlyValid ? `?addOnlyValid=${addOnlyValid}` : ''}`, formData);
      dispatch(addNewLocationsFromCSV(response.data.result || []))
      return response
    } catch (error) {
      dispatch(hasError(error));
      return error
    }
  };
}

export function deleteLocations(id){
  return async () => {
    dispatch(startLoading());
    try {
      const response = await axios.delete(`/locations/${id}` );
      if (response?.data?.status === 'OK') dispatch(deleteLocations())
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}


export function getConfig() {
  return async () => {
    dispatch(startLoading());
    try {
      const response = await axios.get('/config');
      dispatch(setConfig(response.data))
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}


export function getCategories() {
  return async () => {
    dispatch(startLoading());
    try {
      const response = await axios.get(`/categories`);
      dispatch(setCategories(response.data.categories))
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function deleteUserInfo() {
  return async () => {
    dispatch(setUserInfo(
        {},
    ))
  };
}

export function getUserInfo() {
  return async () => {
    dispatch(startLoading());
    try {
      const response = await axios.get(`/user`);
      const { firstName, lastName } = response.data;
      dispatch(setUserInfo(
        {
          ...response.data,
          displayName: `${firstName} ${lastName}`
        }
      ))
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function updateUserInfo(formData) {
  return async () => {
    dispatch(startLoading());
    try {
      const response = await axios.put(`/user`, formData);
      const { firstName, lastName } = response.data;
      dispatch(setUserInfo(
        {
          ...response.data,
          displayName: `${firstName} ${lastName}`
        }
      ))
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}


export function updateUserPhoto(formData) {
  return async () => {
    dispatch(startLoading());
    try {
      const response = await axios.post(`/user/image`, formData);
      const { firstName, lastName } = response.data;
      dispatch(setUserInfo(
        {
          ...response.data,
          displayName: `${firstName} ${lastName}`
        }
      ))
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function uploadMerchantLogo(formData) {
  return async () => {
    dispatch(startLoading());
    try {
      const response = await axios.post(`/merchant/logo`, formData);
      if(response) dispatch(getUserInfo());
    } catch (error) {
      dispatch(hasError(error));
      return error
    }
  };
}


export function uploadMerchantBanner(formData) {
  return async () => {
    dispatch(startLoading());
    try {
      const response = await axios.post(`/merchant/banner`, formData);
    } catch (error) {
      dispatch(hasError(error));
      return error
    }
  };
}

export function uploadEinDocument(formData) {
  return async () => {
    dispatch(startLoading());
    try {
      const response = await axios.post(`/user/ein-document`, formData);
    } catch (error) {
      dispatch(hasError(error));
      return error
    }
  };
}

export function addDroppedSignUp(formData) {
  return async () => {
    dispatch(startLoading());
    try {
      const response = await axios.post(`/user/addDroppedSignUp`, formData);
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

