export const createBranchIOLink = async (referralCode) => {
  const options = {
    method: 'POST',
    headers: {accept: 'application/json', 'content-type': 'application/json'},
    body: JSON.stringify(
      {
        branch_key: process.env.REACT_APP_BRANCH_IO_KEY,
        data: {
          referralCode,
        }
      },
    ),
  };

  return fetch('https://api2.branch.io/v1/url', options)
    .then(response => response.json())
    .then(response => response.url)
    .catch(err => console.error(err));
}