import PropTypes from 'prop-types';
import { createContext, useCallback, useEffect, useReducer, useState } from 'react';
import { CognitoUser, CognitoUserPool, AuthenticationDetails, CognitoUserAttribute, CognitoUserSession } from 'amazon-cognito-identity-js';
import {
  AuthFlowType,
  ChallengeNameType,
  CognitoIdentityProviderClient,
  InitiateAuthCommand,
  RespondToAuthChallengeCommand,
  SignUpCommand,
} from '@aws-sdk/client-cognito-identity-provider';
// utils
import axios from '../utils/axios';
// routes
import { PATH_AUTH } from '../routes/paths';
//
import { COGNITO_API, CLOVER_API } from '../config';
import { LOCAL_STORAGE_KEY } from "@utils/constants";

// ----------------------------------------------------------------------

export const UserPool = new CognitoUserPool({
  UserPoolId: COGNITO_API.userPoolId,
  ClientId: COGNITO_API.clientId,
});

export const AWSClient = new CognitoIdentityProviderClient({
  region: COGNITO_API.region,
  credentials: {
    accessKeyId: COGNITO_API.clientId,
    secretAccessKey: null,
  },
});

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const handlers = {
  AUTHENTICATE: (state, action) => {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  method: 'cognito',
  login: () => Promise.resolve(),
  register: () => Promise.resolve(),
  logout: () => Promise.resolve(),
});

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [cognitoSession, setCognitoSession] = useState(null);

  const getUserAttributes = useCallback(
    (currentUser) =>
      new Promise((resolve, reject) => {
        currentUser.getUserAttributes((err, attributes) => {
          if (err) {
            dispatch({
              type: 'AUTHENTICATE',
              payload: {
                isAuthenticated: false,
                user: null,
              },
            });
            reject(err);
          } else {
            const results = {};

            attributes.forEach((attribute) => {
              results[attribute.Name] = attribute.Value;
            });
            resolve(results);
          }
        });
      }),
    []
  );

  const getSession = useCallback(
    () =>
      new Promise((resolve, reject) => {
        const user = UserPool.getCurrentUser();
        if (user) {
          user.getSession(async (err, session) => {
            if (err) {
              reject(err);
            } else {
              const attributes = await getUserAttributes(user);
              const token = session.getIdToken().getJwtToken();
              // use the token or Bearer depend on the wait BE handle, by default amplify API only need to token.
              axios.defaults.headers.common.Authorization = `Bearer ${token}`;
              dispatch({
                type: 'AUTHENTICATE',
                payload: { isAuthenticated: true, user: attributes },
              });
              resolve({
                user,
                session,
                headers: { Authorization: token },
              });
            }
          });
        } else {
          dispatch({
            type: 'AUTHENTICATE',
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      }),
    [getUserAttributes]
  );

  const initial = useCallback(async () => {
    try {
      await getSession();
    } catch {
      dispatch({
        type: 'AUTHENTICATE',
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
  }, [getSession]);

  useEffect(() => {
    initial();
  }, [initial]);

  // We make sure to handle the user update here, but return the resolve value in order for our components to be
  // able to chain additional `.then()` logic. Additionally, we `.catch` the error and "enhance it" by providing
  // a message that our React components can use.
  const login = useCallback(
    (email, password) =>
      new Promise((resolve, reject) => {
        const user = new CognitoUser({
          Username: email,
          Pool: UserPool,
        });

        const authDetails = new AuthenticationDetails({
          Username: email,
          Password: password,
        });

        user.authenticateUser(authDetails, {
          onSuccess: (data) => {
            getSession();
            resolve(data);
          },
          onFailure: (err) => {
            reject(err);
          },
          newPasswordRequired: () => {
            // Handle this on login page for update password.
            resolve({ message: 'newPasswordRequired' });
          },
        });
      }),
    [getSession]
  );

  // same thing here
  const logout = () => {
    clearCloverLocalStorageKeys();
    const user = UserPool.getCurrentUser();
    if (user) {
      user.signOut();
      dispatch({ type: 'LOGOUT' });
    }
  };

  const initiateCustomChallenge = async (username) => {
    if (!username || typeof username !== 'string') {
      return new Error('Invalid username provided.');
    }

    const initiateAuthParams  = {
      ClientId: UserPool.getClientId(),
      AuthFlow: AuthFlowType.CUSTOM_AUTH,
      AuthParameters: {
        USERNAME: username,
      },
    };
    
    const command = new InitiateAuthCommand(initiateAuthParams);
    
    try {
      const response = await AWSClient.send(command);
      setCognitoSession(response.Session);

      return response;
    } catch (error) {
      console.error("Error initiating custom challenge:", error);
      throw error;
    }
  }

  const register = (email, password, userData) =>
    new Promise((resolve, reject) => {
      const { firstName, lastName, referralCode, companyInfo } = userData;
      const { name, url, street, city, zipCode, MID, ein } = companyInfo;

      const userAttributes = [
        { Name: 'email', Value: email },
        { Name: 'name', Value: `${firstName} ${lastName}` },
      ];

      referralCode && userAttributes.push({ Name: 'custom:referralCode', Value: `${referralCode}` })

      name && userAttributes.push({ Name: 'custom:companyName', Value: `${name}` })
      url && userAttributes.push({ Name: 'custom:companyURL', Value: `${url}` })
      street && userAttributes.push({ Name: 'custom:companyAddress', Value: `${street}` })
      city && userAttributes.push({ Name: 'custom:companyCity', Value: `${city}` })
      zipCode && userAttributes.push({ Name: 'custom:companyZipcode', Value: `${zipCode}` })
      // MID && userAttributes.push({ Name: 'custom:MID', Value: `${MID}` })
      ein && userAttributes.push({ Name: 'custom:ein', Value: `${ein}` })

      UserPool.signUp(
        email,
        password,
        userAttributes,
        null,
        async (err, result) => {
          if (err) {
            reject(err);
            dispatch({
              type: 'AUTHENTICATE',
              payload: {
                isAuthenticated: false,
                user: null,
              },
            });
            return;
          }
          const { user, session, token } = result;
          dispatch({
            type: 'AUTHENTICATE',
            payload: { isAuthenticated: false, user },
          });
          resolve({
            user,
            session,
            headers: { Authorization: token },
          });
        }
      )
    });

  const addCompanyInfo = (companyInfo)   =>
    new Promise((resolve, reject) => {
      const { user } = state;
      const userData = {
        Username: user.username,
        Pool: UserPool,
      };

      const { name, url, street, city, zipCode } = companyInfo;
      const attributeList = [
        { Name: 'custom:companyName', Value: `${name}` }
      ]

      url && attributeList.push({ Name: 'custom:companyURL', Value: `${url}` })
      street && attributeList.push({ Name: 'custom:companyAddress', Value: `${street}` })
      city && attributeList.push({ Name: 'custom:companyCity', Value: `${city}` })
      zipCode && attributeList.push({ Name: 'custom:companyZipcode', Value: `${zipCode}` })

      const cognitoUser = new CognitoUser(userData);

      cognitoUser.updateAttributes(attributeList, (err, result) =>  {
        if (err) {
          console.log(err.message || JSON.stringify(err));
          reject(err);
        }
        console.log({ result });
      });
    });

  const changePassword = async (oldPassword, newPassword) => {
    const { user: currentUser } = await getSession();
    return new Promise((resolve, reject) => {
      currentUser.changePassword(oldPassword, newPassword,  (err, result) => {
        if (err) {
          console.log(err);
          reject(err);
        } else {
          resolve(result);
          return result;
        }
      });
    });
  }
  const emailVerify = (confirmationCode) => {
    const { user, pool } = state;
    const userData = {
      Username: user?.username || localStorage.getItem(LOCAL_STORAGE_KEY.REGISTER_EMAIL),
      Pool: UserPool,
    };
    const cognitoUser = new CognitoUser(userData);

    return new Promise((resolve, reject) => {
      cognitoUser.confirmRegistration(confirmationCode, true,  (err, result) => {
        if (err) {
          console.log(err);
          reject(err);
        } else {
          console.log('verify result', result)
          resolve(result);
          return result;
        }
      });
    });
  }
  const resendConfirmationCode = () => {
    const { user } = state;
    const userData = {
      Username: user?.username || localStorage.getItem(LOCAL_STORAGE_KEY.REGISTER_EMAIL),
      Pool: UserPool,
    };
    const cognitoUser = new CognitoUser(userData);

    return new Promise((resolve, reject) => {
      cognitoUser.resendConfirmationCode( (err, result) => {
        if (err) {
          console.log(err);
          reject(err);
        } else {
          resolve(result);
          return result;
        }
      });
    });
  }

  const forgotPassword = (email) => {
    const userData = {
      Username: email || localStorage.getItem(LOCAL_STORAGE_KEY.EMAIL_RECOVERY),
      Pool: UserPool,
    };
    const cognitoUser = new CognitoUser(userData);

    return new Promise((resolve, reject) => {
      cognitoUser.forgotPassword({
        onSuccess: (data) => {
          // successfully initiated reset password request
          resolve(data);
        },
        onFailure: (err) => {
          reject(err);
        },
      });
    });
  }

  const confirmPassword = (email, verificationCode, newPassword) => {
    const userData = {
      Username: email || localStorage.getItem(LOCAL_STORAGE_KEY.EMAIL_RECOVERY),
      Pool: UserPool,
    };
    const cognitoUser = new CognitoUser(userData);

    return new Promise((resolve, reject) => {
      cognitoUser.confirmPassword(verificationCode, newPassword, {
        onFailure(err) {
          reject(err);
        },
        onSuccess() {
          resolve();
        },
      });
    });
  }

  const handleCloverOAuthCode = useCallback(async (code) => {
    let step = '';
    let registrationData = {};

    try {
      const accessToken = await getAccessTokenFromCode(code);
  
      if (!accessToken) {
          console.error('Failed to obtain access token from Clover');
          return;
      }
  
      localStorage.setItem(LOCAL_STORAGE_KEY.CLOVER_ACCESS_TOKEN, accessToken);
      const cloverUser = await getCloverUser(accessToken);
      const cloverMerchant = await getCloverMerchant(accessToken);
      const userAttributes = await getCloverMerchantAttributes(cloverUser, cloverMerchant);
      const tempPassword = getTempPassword.generate(30);
      registrationData = {
        email: cloverUser.email,
        password: tempPassword
      }

      const signUpCommand = new SignUpCommand({
        ClientId: UserPool.getClientId(),
        Username: registrationData.email,
        Password: tempPassword,
        UserAttributes: userAttributes
      });

      const result = await AWSClient.send(signUpCommand);

      if (result && !result.UserConfirmed) {
        step = 'VERIFY_REGISTER'; 
      } else {
        initiateCustomChallenge(registrationData.email);
        step = 'VERIFY_CUSTOM_AUTH';
      }
    } catch (error) {
      if (error.name === "UsernameExistsException") {
        initiateCustomChallenge(registrationData.email);
        step = 'VERIFY_CUSTOM_AUTH';
      } else {
         console.error('Unexpected error during Clover OAuth:', error);
      }
    }
    
    return { step, registrationData };
  }, []);

  const getCloverMerchantAttributes= async (cloverUser, cloverMerchant) => {
    const { name, url, id: MID, ein } = cloverMerchant;
    const { address1: street, city, zip: zipCode } = cloverMerchant.address;

    const userAttributes = [
      { Name: 'email', Value: cloverUser.email },
      { Name: 'name', Value: cloverUser.name },
    ];

    name && userAttributes.push({ Name: 'custom:companyName', Value: `${name}` })
    url && userAttributes.push({ Name: 'custom:companyURL', Value: `${url}` })
    street && userAttributes.push({ Name: 'custom:companyAddress', Value: `${street}` })
    city && userAttributes.push({ Name: 'custom:companyCity', Value: `${city}` })
    zipCode && userAttributes.push({ Name: 'custom:companyZipcode', Value: `${zipCode}` })
    //MID && userAttributes.push({ Name: 'custom:MID', Value: `${MID}` })
    ein && userAttributes.push({ Name: 'custom:ein', Value: `${ein}` })

    return userAttributes;
  };

  const getAccessTokenFromCode = async (cloverOAuthCode) => {
    try {
      // Request to Clover API to exchange the code for an access token
      const redirectUri = `${process.env.REACT_APP_URL}`;
      const tokenUrl = `${CLOVER_API.cloverApi}/oauth/token`;
      const customConfig = {
        headers: {
          'Content-Type': 'application/json'
        }
      };
      const params = JSON.stringify({
        client_id: CLOVER_API.clientId,
        client_secret: CLOVER_API.clientSecret,
        code: cloverOAuthCode,
        redirect_uri: redirectUri,
        grant_type: 'authorization_code',
        state: 'your_unique_state_value'
      });
      
      const response = await axios.post(tokenUrl, params, customConfig);

      return response.data.access_token;
    } catch (error) {
      throw new Error('Clover OAuth code exchange failed');
    }
  };

  const getCloverUser = async (accessToken) => {
    const merchantId = localStorage.getItem(LOCAL_STORAGE_KEY.CLOVER_MERCHANT_ID);
    const employeeId = localStorage.getItem(LOCAL_STORAGE_KEY.CLOVER_EMPLOYEE_ID);
    const response = await axios.get(`${CLOVER_API.cloverApi}/v3/merchants/${merchantId}/employees/${employeeId}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    
    const user = {
      id: response.data.id,
      name: response.data.name,
      email: response.data.email
    };

    return user;
  };

  const getCloverMerchant = async (accessToken) => {
    const merchantId = localStorage.getItem(LOCAL_STORAGE_KEY.CLOVER_MERCHANT_ID);
    const cloverMerchant = await axios.get(`${CLOVER_API.cloverApi}/v3/merchants/${merchantId}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    
    const merchantAddress = await axios.get(`${CLOVER_API.cloverApi}/v3/merchants/${merchantId}/address`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const merchant = {
      id: cloverMerchant.data.id,
      name: cloverMerchant.data.name,
      url: cloverMerchant.data.website,
      address: merchantAddress.data
    };

    return merchant;
  };

  const getCloverAuthorizationCode = async () => {
    try {
      // Construct the Clover OAuth URL for authorization
      const applicationHost = `${window.location.protocol}//${window.location.host}`;
      const cloverAuthUrl = `${CLOVER_API.cloverApi}/oauth/authorize` +
        `?client_id=${CLOVER_API.clientId}` +
        `&redirect_uri=${applicationHost}/auth/callback` +
        '&response_type=code';

      // Redirect the user to the Clover authorization URL
      window.location.href = cloverAuthUrl;
    } catch (error) {
      throw new Error('Clover authorization failed');
    }
  };

  const getTempPassword = (() => {
    const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()-_=+[]{}|;:,.<>?';
    
    function generate(length = 12) {
        const values = new Uint8Array(length);
        if (window.crypto && window.crypto.getRandomValues) {
            window.crypto.getRandomValues(values);
        } else if (window.msCrypto && window.msCrypto.getRandomValues) {
            window.msCrypto.getRandomValues(values);
        } else {
            throw new Error('Secure random number generation is not supported by this browser.');
        }
        return Array.from(values).map(value => charset[value % charset.length]).join('');
    }

    return {
        generate
    };
  })();

  const clearCloverLocalStorageKeys = () => {
    localStorage.removeItem(LOCAL_STORAGE_KEY.CLOVER_EMAIL);
    localStorage.removeItem(LOCAL_STORAGE_KEY.CLOVER_ACCESS_TOKEN);
    localStorage.removeItem(LOCAL_STORAGE_KEY.CLOVER_MERCHANT_ID);
    localStorage.removeItem(LOCAL_STORAGE_KEY.CLOVER_EMPLOYEE_ID);
  };

  const verifyCustomChallenge = async (username, verificationCode) => {
    if (!cognitoSession) {
      console.error('No session available');
      return;
    }

    const respondToAuthChallengeParams  = {
      ClientId: UserPool.getClientId(),
      ChallengeName: ChallengeNameType.CUSTOM_CHALLENGE,
      Session: cognitoSession,
      ChallengeResponses: {
        ANSWER: verificationCode,
        USERNAME: username,
      },
    };

    const command = new RespondToAuthChallengeCommand(respondToAuthChallengeParams);

    try {
      const response = await AWSClient.send(command);
      setCognitoSession(response.Session);

      if (response.AuthenticationResult) {
        const { IdToken, AccessToken, RefreshToken } = response.AuthenticationResult;
        
        axios.defaults.headers.common.Authorization = `Bearer ${AccessToken}`;
        dispatch({
          type: 'AUTHENTICATE',
          payload: { isAuthenticated: true, user: null },
        });
      }

      return response;
    } catch (error) {
      console.error("Error responding to custom challenge:", error);

      if (error.name === 'NotAuthorizedException' && error.message.includes('Incorrect username or password')) {
        throw new Error("You've tried too many times. Please reset your authentication process.");
      } else {
          throw error;
      }
    }
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'cognito',
        user: !!state?.user && {
          displayName: state?.user?.name || 'Minimals',
          role: 'admin',
          ...state.user,
        } || null,
        login,
        register,
        emailVerify,
        addCompanyInfo,
        resendConfirmationCode,
        changePassword,
        forgotPassword,
        confirmPassword,
        logout,
        getCloverAuthorizationCode,
        handleCloverOAuthCode,
        getCloverUser,
        verifyCustomChallenge,
        clearCloverLocalStorageKeys
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
