import { pxToRem, responsiveFontSizes } from '../utils/getFontValue';

// ----------------------------------------------------------------------

const FONT_PRIMARY = 'Montserrat, sans-serif'; // Google Font
// const FONT_SECONDARY = 'CircularStd, sans-serif'; // Local Font

const typography = {
  fontFamily: FONT_PRIMARY,
  fontWeightRegular: 400,
  fontWeightMedium: 600,
  fontWeightBold: 700,
  h1: {
    fontWeight: 600, 
    fontSize: pxToRem(64), 
    lineHeight: "80px",
    letterSpacing: 2,
    // ...responsiveFontSizes({ sm: 52, md: 58, lg: 64 }),
  },
  h2: {
    fontWeight: 600, 
    fontSize: pxToRem(48), 
    lineHeight: "64px",
    // ...responsiveFontSizes({ sm: 40, md: 44, lg: 48 }),
  },
  h3: {
    fontWeight: 600, 
    fontSize: pxToRem(32), 
    lineHeight: "48px",
    // ...responsiveFontSizes({ sm: 26, md: 30, lg: 32 }),
  },
  h4: {
    fontWeight: 600, 
    fontSize: pxToRem(24), 
    lineHeight: "36px"
    // ...responsiveFontSizes({ sm: 20, md: 24, lg: 24 }),
  },
  h5: {
    fontWeight: 600, 
    fontSize: pxToRem(20), 
    lineHeight: "30px",
    // ...responsiveFontSizes({ sm: 19, md: 20, lg: 20 }),
  },
  h6: {
    fontWeight: 600, 
    fontSize: pxToRem(18), 
    lineHeight: "28px",
    // ...responsiveFontSizes({ sm: 18, md: 18, lg: 18 }),
  },
  subtitle1: {
    fontWeight: 600, 
    fontSize: pxToRem(16), 
    lineHeight: "24px",
  },
  subtitle2: {
    fontWeight: 600, 
    fontSize: pxToRem(14), 
    lineHeight: "22px",
  },
  body1: {
    fontWeight: 500, 
    fontSize: pxToRem(16),
    lineHeight: "24px",
  },
  body2: {
    fontWeight: 500, 
    fontSize: pxToRem(14),
    lineHeight: "22px",
  },
  caption: {
    lineHeight: 1.5,
    fontSize: pxToRem(12),
  },
  overline: {
    fontWeight: 600,
    fontSize: pxToRem(12),
    lineHeight: "18px",
    letterSpacing: "0.03em",
    textTransform: 'uppercase',
  },
  button: {
    fontWeight: 500,
    lineHeight: 24 / 14,
    fontSize: pxToRem(14),
    textTransform: 'capitalize',
  },
};

export default typography;
