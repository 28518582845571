import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();

  const PRIMARY_LIGHT = theme.palette.primary.light;

  const PRIMARY_MAIN = theme.palette.primary.main;

  const PRIMARY_DARK = theme.palette.primary.dark;

  // OR
  // const logo = '/logo/logo_single.svg';

  const logo = (
    <Box sx={{ width: 31, height: 31, ...sx }}>
      <svg width="119" height="26" viewBox="0 0 119 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="1" y="0.930359" width="24.1392" height="24.1392" rx="12.0696" fill="#002433"/>
        <path d="M13.4054 18.0373C12.7696 18.0373 12.2233 17.903 11.7666 17.6343C11.3099 17.3656 10.9607 16.9582 10.7189 16.4119C10.4771 15.8567 10.3562 15.1582 10.3562 14.3164C10.3562 13.4656 10.4816 12.7671 10.7323 12.2209C10.992 11.6746 11.3502 11.2671 11.8069 10.9985C12.2636 10.7298 12.7965 10.5955 13.4054 10.5955C14.086 10.5955 14.695 10.7477 15.2323 11.0522C15.7786 11.3567 16.2084 11.7865 16.5219 12.3418C16.8442 12.897 17.0054 13.5552 17.0054 14.3164C17.0054 15.0686 16.8442 15.7224 16.5219 16.2776C16.2084 16.8328 15.7786 17.2671 15.2323 17.5806C14.695 17.885 14.086 18.0373 13.4054 18.0373ZM9.13379 17.9298V7.96265L11.2293 8.36164V12.1671L11.095 14.303L11.1353 16.4522V17.9298H9.13379ZM13.0427 16.3179C13.392 16.3179 13.701 16.2373 13.9696 16.0761C14.2472 15.9149 14.4666 15.6865 14.6278 15.391C14.798 15.0865 14.883 14.7283 14.883 14.3164C14.883 13.8955 14.798 13.5373 14.6278 13.2418C14.4666 12.9462 14.2472 12.7179 13.9696 12.5567C13.701 12.3955 13.392 12.3149 13.0427 12.3149C12.6935 12.3149 12.3801 12.3955 12.1024 12.5567C11.8248 12.7179 11.6054 12.9462 11.4442 13.2418C11.283 13.5373 11.2024 13.8955 11.2024 14.3164C11.2024 14.7283 11.283 15.0865 11.4442 15.391C11.6054 15.6865 11.8248 15.9149 12.1024 16.0761C12.3801 16.2373 12.6935 16.3179 13.0427 16.3179Z" fill="white"/>
        <path d="M18.998 17.9816C19.6025 17.9816 20.0995 17.5114 20.0995 16.8666C20.0995 16.195 19.6025 15.7517 18.998 15.7517C18.3935 15.7517 17.8965 16.195 17.8965 16.8666C17.8965 17.5114 18.3935 17.9816 18.998 17.9816Z" fill="white"/>
        <rect x="0.85671" y="0.787069" width="24.4258" height="24.4258" rx="12.2129" stroke="#002433" strokeOpacity="0.08" strokeWidth="0.286581"/>
        <path d="M36.6885 18.1363C35.8794 18.1363 35.1843 17.9653 34.6031 17.6235C34.022 17.2816 33.5776 16.7631 33.2699 16.068C32.9622 15.3615 32.8084 14.4727 32.8084 13.4015C32.8084 12.319 32.9679 11.4302 33.287 10.7351C33.6174 10.0399 34.0733 9.52146 34.6544 9.1796C35.2356 8.83774 35.9136 8.66681 36.6885 8.66681C37.5545 8.66681 38.3294 8.86053 39.0131 9.24797C39.7082 9.63541 40.2552 10.1824 40.654 10.8889C41.0643 11.5954 41.2694 12.4329 41.2694 13.4015C41.2694 14.3587 41.0643 15.1906 40.654 15.8971C40.2552 16.6036 39.7082 17.1563 39.0131 17.5551C38.3294 17.9426 37.5545 18.1363 36.6885 18.1363ZM31.2529 17.9995V5.3166L33.9194 5.82431V10.6667L33.7485 13.3845L33.7998 16.1193V17.9995H31.2529ZM36.227 15.9484C36.6714 15.9484 37.0645 15.8458 37.4064 15.6407C37.7596 15.4356 38.0388 15.145 38.2439 14.769C38.4604 14.3815 38.5687 13.9257 38.5687 13.4015C38.5687 12.866 38.4604 12.4102 38.2439 12.0341C38.0388 11.6581 37.7596 11.3675 37.4064 11.1624C37.0645 10.9573 36.6714 10.8547 36.227 10.8547C35.7825 10.8547 35.3837 10.9573 35.0305 11.1624C34.6772 11.3675 34.398 11.6581 34.1929 12.0341C33.9878 12.4102 33.8852 12.866 33.8852 13.4015C33.8852 13.9257 33.9878 14.3815 34.1929 14.769C34.398 15.145 34.6772 15.4356 35.0305 15.6407C35.3837 15.8458 35.7825 15.9484 36.227 15.9484Z" fill="#002433"/>
        <path d="M47.6182 18.1363C46.5698 18.1363 45.6468 17.9312 44.8491 17.5209C44.0629 17.1107 43.4532 16.5523 43.0202 15.8458C42.5872 15.1279 42.3707 14.3132 42.3707 13.4015C42.3707 12.4785 42.5815 11.6638 43.0031 10.9573C43.4361 10.2394 44.023 9.68099 44.7637 9.28216C45.5044 8.87193 46.3419 8.66681 47.2763 8.66681C48.1765 8.66681 48.9856 8.86053 49.7035 9.24797C50.4328 9.62401 51.0083 10.171 51.4299 10.8889C51.8515 11.5954 52.0623 12.4443 52.0623 13.4357C52.0623 13.5383 52.0566 13.6579 52.0452 13.7947C52.0338 13.92 52.0224 14.0397 52.0111 14.1536H44.5415V12.5982H50.6094L49.5839 13.0597C49.5839 12.5811 49.487 12.1652 49.2933 11.8119C49.0996 11.4587 48.8318 11.1852 48.4899 10.9914C48.1481 10.7863 47.7492 10.6838 47.2934 10.6838C46.8376 10.6838 46.4331 10.7863 46.0798 10.9914C45.738 11.1852 45.4702 11.4644 45.2765 11.829C45.0827 12.1823 44.9859 12.6039 44.9859 13.0939V13.5041C44.9859 14.0055 45.0941 14.4499 45.3106 14.8373C45.5385 15.2134 45.8519 15.504 46.2507 15.7091C46.661 15.9028 47.1396 15.9997 47.6865 15.9997C48.1765 15.9997 48.6039 15.9256 48.9685 15.7775C49.3446 15.6293 49.6864 15.4071 49.9941 15.1108L51.4128 16.6492C50.9912 17.1278 50.4613 17.4981 49.8232 17.7602C49.185 18.0109 48.45 18.1363 47.6182 18.1363Z" fill="#002433"/>
        <path d="M59.427 8.66681C60.1563 8.66681 60.8058 8.81495 61.3756 9.11123C61.9567 9.39611 62.4125 9.84052 62.743 10.4445C63.0735 11.037 63.2387 11.8005 63.2387 12.7349V17.9995H60.5722V13.1452C60.5722 12.4045 60.407 11.8575 60.0765 11.5042C59.7574 11.151 59.3016 10.9744 58.7091 10.9744C58.2874 10.9744 57.9057 11.0655 57.5638 11.2478C57.2334 11.4188 56.9713 11.6866 56.7776 12.0512C56.5952 12.4159 56.5041 12.8831 56.5041 13.4528V17.9995H53.8376V8.80356H56.3844V11.3504L55.9058 10.5812C56.2363 9.96587 56.7092 9.49297 57.3245 9.16251C57.9399 8.83204 58.6407 8.66681 59.427 8.66681Z" fill="#002433"/>
        <path d="M64.972 21.4523C64.5618 21.4523 64.1686 21.4067 63.7926 21.3156C63.4166 21.2358 63.0975 21.1104 62.8354 20.9395L63.5533 19.008C63.861 19.2245 64.237 19.3328 64.6814 19.3328C65.0119 19.3328 65.274 19.2188 65.4677 18.9909C65.6728 18.7744 65.7754 18.4439 65.7754 17.9995V8.80356H68.4419V17.9824C68.4419 19.0422 68.1399 19.8854 67.5359 20.5122C66.932 21.1389 66.0774 21.4523 64.972 21.4523ZM67.1086 7.52159C66.6186 7.52159 66.2198 7.37915 65.9121 7.09427C65.6044 6.80938 65.4506 6.45613 65.4506 6.03451C65.4506 5.61288 65.6044 5.25963 65.9121 4.97475C66.2198 4.68987 66.6186 4.54742 67.1086 4.54742C67.5986 4.54742 67.9974 4.68417 68.3051 4.95765C68.6128 5.21975 68.7666 5.5616 68.7666 5.98323C68.7666 6.42764 68.6128 6.79799 68.3051 7.09427C68.0088 7.37915 67.61 7.52159 67.1086 7.52159Z" fill="#002433"/>
        <path d="M76.5378 17.9995V16.2048L76.3668 15.8116V12.5982C76.3668 12.0284 76.1902 11.584 75.837 11.2649C75.4951 10.9459 74.9652 10.7863 74.2473 10.7863C73.7573 10.7863 73.273 10.8661 72.7944 11.0256C72.3272 11.1738 71.9284 11.3789 71.5979 11.641L70.6407 9.77785C71.1421 9.4246 71.746 9.15111 72.4526 8.95739C73.1591 8.76367 73.877 8.66681 74.6063 8.66681C76.0079 8.66681 77.0961 8.99728 77.871 9.6582C78.6459 10.3191 79.0333 11.3504 79.0333 12.752V17.9995H76.5378ZM73.7345 18.1363C73.0166 18.1363 72.4013 18.0166 71.8885 17.7773C71.3757 17.5266 70.9826 17.1905 70.7091 16.7688C70.4356 16.3472 70.2988 15.8743 70.2988 15.3501C70.2988 14.8032 70.4299 14.3246 70.692 13.9143C70.9655 13.5041 71.3928 13.185 71.974 12.9571C72.5551 12.7178 73.3129 12.5982 74.2473 12.5982H76.6916V14.1536H74.5379C73.9111 14.1536 73.4781 14.2562 73.2388 14.4613C73.0109 14.6664 72.897 14.9228 72.897 15.2305C72.897 15.5723 73.028 15.8458 73.2901 16.0509C73.5636 16.2447 73.9339 16.3415 74.4011 16.3415C74.8456 16.3415 75.2444 16.239 75.5976 16.0338C75.9509 15.8173 76.2073 15.504 76.3668 15.0937L76.7771 16.3244C76.5833 16.917 76.2301 17.3671 75.7173 17.6748C75.2045 17.9824 74.5436 18.1363 73.7345 18.1363Z" fill="#002433"/>
        <path d="M93.2236 8.66681C93.9529 8.66681 94.5968 8.81495 95.1551 9.11123C95.7249 9.39611 96.1693 9.84052 96.4884 10.4445C96.8188 11.037 96.9841 11.8005 96.9841 12.7349V17.9995H94.3176V13.1452C94.3176 12.4045 94.1637 11.8575 93.8561 11.5042C93.5484 11.151 93.1154 10.9744 92.557 10.9744C92.1696 10.9744 91.822 11.0655 91.5143 11.2478C91.2067 11.4188 90.9674 11.6809 90.7964 12.0341C90.6255 12.3874 90.54 12.8375 90.54 13.3845V17.9995H87.8736V13.1452C87.8736 12.4045 87.7197 11.8575 87.412 11.5042C87.1158 11.151 86.6884 10.9744 86.1301 10.9744C85.7426 10.9744 85.3951 11.0655 85.0874 11.2478C84.7797 11.4188 84.5404 11.6809 84.3695 12.0341C84.1986 12.3874 84.1131 12.8375 84.1131 13.3845V17.9995H81.4466V8.80356H83.9935V11.3162L83.5149 10.5812C83.8339 9.95448 84.284 9.48157 84.8652 9.16251C85.4578 8.83204 86.1301 8.66681 86.8822 8.66681C87.7254 8.66681 88.4604 8.88332 89.0872 9.31634C89.7253 9.73797 90.1469 10.3875 90.352 11.2649L89.4119 11.0085C89.7196 10.2906 90.2096 9.72087 90.8819 9.29925C91.5656 8.87763 92.3462 8.66681 93.2236 8.66681Z" fill="#002433"/>
        <path d="M99.3741 17.9995V8.80356H102.041V17.9995H99.3741ZM100.707 7.52159C100.217 7.52159 99.8186 7.37915 99.5109 7.09427C99.2032 6.80938 99.0494 6.45613 99.0494 6.03451C99.0494 5.61288 99.2032 5.25963 99.5109 4.97475C99.8186 4.68987 100.217 4.54742 100.707 4.54742C101.197 4.54742 101.596 4.68417 101.904 4.95765C102.212 5.21975 102.365 5.5616 102.365 5.98323C102.365 6.42764 102.212 6.79799 101.904 7.09427C101.608 7.37915 101.209 7.52159 100.707 7.52159Z" fill="#002433"/>
        <path d="M110.105 8.66681C110.834 8.66681 111.484 8.81495 112.053 9.11123C112.634 9.39611 113.09 9.84052 113.421 10.4445C113.751 11.037 113.916 11.8005 113.916 12.7349V17.9995H111.25V13.1452C111.25 12.4045 111.085 11.8575 110.754 11.5042C110.435 11.151 109.979 10.9744 109.387 10.9744C108.965 10.9744 108.583 11.0655 108.242 11.2478C107.911 11.4188 107.649 11.6866 107.455 12.0512C107.273 12.4159 107.182 12.8831 107.182 13.4528V17.9995H104.515V8.80356H107.062V11.3504L106.584 10.5812C106.914 9.96587 107.387 9.49297 108.002 9.16251C108.618 8.83204 109.318 8.66681 110.105 8.66681Z" fill="#002433"/>
        <path d="M117.342 18.1363C116.886 18.1363 116.493 17.9824 116.163 17.6748C115.843 17.3557 115.684 16.9512 115.684 16.4612C115.684 15.9712 115.843 15.578 116.163 15.2818C116.493 14.9741 116.886 14.8203 117.342 14.8203C117.809 14.8203 118.202 14.9741 118.521 15.2818C118.84 15.578 119 15.9712 119 16.4612C119 16.9512 118.84 17.3557 118.521 17.6748C118.202 17.9824 117.809 18.1363 117.342 18.1363Z" fill="#002433"/>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
