import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { useTheme } from '@mui/material/styles';
import { Avatar as MUIAvatar } from '@mui/material';

// ----------------------------------------------------------------------

const Avatar = forwardRef(({ /*color = 'default',*/ children, sx, ...other }, ref) => {
  const theme = useTheme();

  /*if (color === 'default') {
    return (
      <MUIAvatar ref={ref} sx={sx} {...other}>
        {children}
      </MUIAvatar>
    );
  }*/

  return (
    <MUIAvatar
      ref={ref}
      sx={{
        fontWeight: theme.typography.fontWeightMedium,
        /*color: theme.palette[color].contrastText,*/
        backgroundColor: 'transparent',
        border: '1px solid #DBE1E7',
        ...sx,
      }}
      {...other}
    >
      {children}
    </MUIAvatar>
  );
});

Avatar.propTypes = {
  children: PropTypes.node,
  sx: PropTypes.object,
  color: PropTypes.oneOf(['default', 'primary', 'secondary', 'info', 'success', 'warning', 'error']),
};

export default Avatar;
