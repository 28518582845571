import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
// @mui
import { Card, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
//
import Image from '../Image';
import Iconify from '../Iconify';
import RejectionFiles from './RejectionFiles';
import { BANNER_REQ_TEXT, LOGO_REQ_TEXT } from "@utils/imageValidators";

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme, isLogo }) => ({
  width: isLogo ? 128 : '100%',
  height: 128,
  margin: 'auto',
  borderRadius: isLogo ? '50%' : '3px',
  padding: theme.spacing(1),
  border: `1px dashed ${theme.palette.grey[500_32]}`,
  marginBottom: '20px',
}));

const HeadingStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '20px 24px',
  fontWeight: 600,
  textAlign: 'left',
  borderBottom: `1px solid ${theme.palette.grey[500_32]}`,
}));

const ContentStyle = styled('div')(({ theme }) => ({
  padding: '46px 24px',
}));


const DropZoneStyle = styled('div')(({theme, isLogo}) => {
  return ({
    zIndex: 0,
    width: '100%',
    height: '100%',
    outline: 'none',
    display: 'flex',
    overflow: 'hidden',
    borderRadius: isLogo ? '50%' : '3px',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
    '& > *': { width: '100%', height: '100%' },
    '&:hover': {
      cursor: 'pointer',
      '& .placeholder': {
        zIndex: 9,
      },
    },
  })
});

const PlaceholderStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  position: 'absolute',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  color: theme.palette.common.white,
  backgroundColor: theme.palette.background.darkOverlay,
  transition: theme.transitions.create('opacity', {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&:hover': { opacity: 0.72 },
}));

// ----------------------------------------------------------------------

UploadCompanyImages.propTypes = {
  error: PropTypes.bool,
  isLogo: PropTypes.bool,
  disabled: PropTypes.bool,
  hideHint: PropTypes.bool,
  inputHint: PropTypes.object,
  file: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  helperText: PropTypes.node,
  sx: PropTypes.object,
};

export default function UploadCompanyImages({ error, file, helperText, sx, isLogo, disabled, hideHint = false, inputHint, ...other }) {
  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    multiple: false,
    disabled,
    ...other,
  });

  return (
    <>
      <Card
        sx={{
          textAlign: 'center',
        }}
      >
        <HeadingStyle>
          {
            isLogo ? 'Company logo' : 'Company banner'
          }
          {
            disabled && inputHint
          }
        </HeadingStyle>
        <ContentStyle>
          <RootStyle
            sx={{
              ...((isDragReject || error) && {
                borderColor: 'error.light',
              }),
              ...sx,
            }}
            isLogo={isLogo}
          >
            <DropZoneStyle
              {...getRootProps()}
              isLogo={isLogo}
              sx={{
                ...(isDragActive && { opacity: 0.72 }),
              }}
            >
              <input {...getInputProps()} />

              {file && <Image alt="avatar" src={typeof file === 'string' ? file : file.preview} sx={{ zIndex: 8 }} />}

              {
                !disabled && (
                  <PlaceholderStyle
                    className="placeholder"
                    sx={{
                      ...(file && {
                        opacity: 0,
                        color: 'common.white',
                        bgcolor: 'grey.900',
                        '&:hover': { opacity: 0.72 },
                      }),
                      ...((isDragReject || error) && {
                        bgcolor: 'error.lighter',
                      }),
                    }}
                  >
                    <Iconify icon={'ic:round-add-a-photo'} sx={{ width: 24, height: 24, mb: 1 }} />
                    <Typography variant="caption">{file ? 'Update photo' : 'Upload photo'}</Typography>
                  </PlaceholderStyle>
                )
              }
            </DropZoneStyle>
          </RootStyle>
          {
            !disabled && !hideHint && (
              <>
                <Typography
                  sx={{
                    whiteSpace: 'pre-wrap',
                    fontSize: '12px',
                    maxWidth: '80%',
                    mx: 'auto',
                    color: theme => theme.palette.grey[500],
                  }}
                >
                  {isLogo ? LOGO_REQ_TEXT : BANNER_REQ_TEXT}
                </Typography>
              </>
            )
          }

        </ContentStyle>
      </Card>
      {helperText && helperText}

      {fileRejections.length > 0 && <RejectionFiles fileRejections={fileRejections} />}
    </>
  );
}
