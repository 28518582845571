import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { TextField } from '@mui/material';

// ----------------------------------------------------------------------

RHFTextField.propTypes = {
  name: PropTypes.string,
  parser: PropTypes.func,
};

export default function RHFTextField({ name, parser, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        if(parser) field.value = parser(field.value)
        return(
        <TextField {...field} fullWidth error={!!error} helperText={error?.message} {...other} />
      )}}
    />
  );
}
