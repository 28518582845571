import { paramCase } from 'change-case';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import {
  Box,
  Tab,
  Tabs,
  Card,
  Table,
  Switch,
  Button,
  Tooltip,
  Divider,
  TableBody,
  Container,
  IconButton,
  TableContainer,
  TablePagination,
  FormControlLabel,
} from '@mui/material';

// hooks
import useTable, { getComparator, emptyRows } from '@hooks/useTable';
// _mock_
import { _userList } from '@src/_mock';
// components
import Scrollbar from '@components/Scrollbar';
import Iconify from '@components/Iconify';
import { TableEmptyRows, TableHeadCustom, TableNoData, TableSelectedActions, TableSkeleton } from '@components/table';
// sections
import BenCampaignTableToolbar from './BenCampaignTableToolbar';
import BenCampaignTableRow from './BenCampaignTableRow';
import { dispatch, useSelector } from "@redux/store";
import { deleteCampaigns, getCampaigns } from "@redux/slices/dashboard";
import { DEFAULT_TABLE_PAGINATION_ROWS } from "@utils/constants";


const TABLE_HEAD = [
  { id: 'name', label: 'Name', align: 'left' },
  { id: 'location', label: 'Location', align: 'left' },
  { id: 'endDate', label: 'Ends', align: 'left' },
  // { id: 'claimed', label: 'Claimed', align: 'left' },
  { id: 'status', label: 'Status', align: 'left' },
  { id: '' },
];

// ----------------------------------------------------------------------

export default function BenCampaignsTable({ active = false}) {
  const { campaigns, isLoading } = useSelector((state) => state.dashboard);
  const { locationsAll } = useSelector((state) => state.account);

  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    //
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable({
    defaultRowsPerPage: campaigns.limit,
  });

  const [filterName, setFilterName] = useState('');
  const [filterLocation, setFilterLocation] = useState('');
  const [tableData, setTableData] = useState([]);

  const handleFilterLocation = (event) => {
    setFilterLocation(event.target.value);
  };

  const handleFilterName = (filterName) => {
    setFilterName(filterName);
    // setPage(0);
  };

  const handleDeleteRow = (id) => {
    const deleteRow = tableData.filter((row) => row.id !== id);
    setSelected([]);
    setTableData(deleteRow);
    dispatch(deleteCampaigns(id));
  };

  const handleDeleteRows = (selected) => {
    const deleteRows = tableData.filter((row) => !selected.includes(row.id));
    setSelected([]);
    setTableData(deleteRows);
    selected?.forEach((id) => dispatch(deleteCampaigns(id)))
  };

  const dataFiltered = applySortFilter({
    tableData,
    comparator: getComparator(order, orderBy),
    // filterName,
    // filterRole,
    // filterStatus,
  });


  // const dataFiltered = tableData

  const denseHeight = dense ? 52 : 72;

  const isNotFound = (!isLoading && !tableData.length);

   useEffect( () => {
     dispatch(getCampaigns({
       limit: rowsPerPage,
       start: page*rowsPerPage,
       active,
       locationId: filterLocation,
       search: filterName,
     }));
   }, [dispatch, rowsPerPage, page, filterLocation, filterName ]);

  useEffect(() => {
    setTableData(campaigns.results);
  }, [campaigns]);

  return (
    <Card>
      <BenCampaignTableToolbar
        active={active}
        filterName={filterName}
        onFilterName={handleFilterName}
        filterLocation={filterLocation}
        onFilterLocationChange={handleFilterLocation}
        optionsLocation={locationsAll}
      />

      <Scrollbar>
        <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
          {selected.length > 0 && (
            <TableSelectedActions
              numSelected={selected.length}
              rowCount={tableData.length}
              onSelectAllRows={(checked) =>
                onSelectAllRows(
                  checked,
                  tableData.map((row) => row.id)
                )
              }
              actions={
                <Tooltip title="Delete">
                  <IconButton color="primary" onClick={() => handleDeleteRows(selected)}>
                    <Iconify icon={'eva:trash-2-outline'} />
                  </IconButton>
                </Tooltip>
              }
            />
          )}

          <Table size={'medium'}>
            <TableHeadCustom
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              rowCount={dataFiltered.length}
              numSelected={selected.length}
              onSort={onSort}
              onSelectAllRows={(checked) =>
                onSelectAllRows(
                  checked,
                  dataFiltered.map((row) => row.id)
                )
              }
            />

            <TableBody>
              {(isLoading ? [...Array(rowsPerPage)] : dataFiltered)
                // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) =>
                  row ? (
                    <BenCampaignTableRow
                      key={row.id}
                      row={row}
                      selected={selected.includes(row.id)}
                      onSelectRow={() => onSelectRow(row.id)}
                      onDeleteRow={() => handleDeleteRow(row.id)}
                      // onEditRow={() => handleEditRow(row.name)}
                    />
                  ) : (
                    !isNotFound && <TableSkeleton key={index} sx={{ height: denseHeight }} />
                  )
              )}

              {/*<TableEmptyRows height={denseHeight} emptyRows={emptyRows(page, rowsPerPage, tableData.length)} />*/}

              <TableNoData isNotFound={isNotFound} />
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>

      <Box sx={{ position: 'relative' }}>
        <TablePagination
          rowsPerPageOptions={DEFAULT_TABLE_PAGINATION_ROWS}
          component="div"
          count={campaigns.total}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={onChangePage}
          onRowsPerPageChange={onChangeRowsPerPage}
        />
      </Box>
    </Card>
  );
}


// ----------------------------------------------------------------------

function applySortFilter({ tableData, comparator, filterName, filterStatus, filterRole }) {
  const stabilizedThis = tableData.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  tableData = stabilizedThis.map((el) => el[0]);

  if (filterName) {
    tableData = tableData.filter((item) => item.name.toLowerCase().indexOf(filterName.toLowerCase()) !== -1);
  }

  // if (filterStatus !== 'all') {
  //   tableData = tableData.filter((item) => item.status === filterStatus);
  // }
  //
  // if (filterRole !== 'all') {
  //   tableData = tableData.filter((item) => item.role === filterRole);
  // }

  return tableData;
}

