import React from "react";
import Slider from "react-slick";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import SlideOne from '../../assets/img/SlideOne.svg';
import SlideTwo from '../../assets/img/SlideTwo.svg';
import SlideThree from '../../assets/img/SlideThree.svg';
import SlideFour from '../../assets/img/SlideFour.svg'

const SlideStyle = styled('div')(({ theme }) => ({
    background: 'rgba(13, 20, 28, 0.1)',
    backdropFilter: 'blur(37px)',
    borderRadius: '42px',
    maxWidth: 430,
    height: 448,
    marginRight: '300px',
    cursor: 'pointer',
}));


export default function LoginCarousel() {

    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        speed: 500,
        arrows: false,
    }

    return (
        <Box sx={{maxWidth: 884, backgroundColor: 'transparent', position: 'absolute', top: '271px', left: '84px', zIndex: 2,}}>
            <Slider {...settings} >
                <SlideStyle>
                    <div style={{border: '1px solid rgba(255, 255, 255, 0.2)',borderRadius: '42px',}}>
                        <img src={SlideOne} alt="SlideOne"/>
                    </div>
                </SlideStyle>
                <SlideStyle>
                    <div>
                        <img src={SlideTwo} alt="SlideTwo"/>
                    </div>
                </SlideStyle>
                <SlideStyle>
                    <div>
                        <img src={SlideThree} alt="SlideThree"/>
                    </div>
                </SlideStyle>
                <SlideStyle>
                    <div>
                        <img src={SlideFour} alt="SlideFour"/>
                    </div>
                </SlideStyle>
            </Slider>
        </Box>
    );
}