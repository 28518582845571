import { capitalCase } from 'change-case';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Card, Stack, Link, Alert, Tooltip, Container, Typography } from '@mui/material';
// routes
import { PATH_AUTH } from '@routes/paths';
// hooks
import useAuth from '@hooks/useAuth';
import useResponsive from '@hooks/useResponsive';
// components
import Page from '@components/Page';
import LogoWithName from '@components/LogoWithName';
import Image from '@components/Image';
// sections
import { LoginForm } from '@src/sections/auth/login';
import Vector from '../../assets/icon/waves_SignUp_new.svg';
import LogoSvg from '../../assets/icon/LogoSignUp.svg';
import LogoLight from '../../assets/icon/logo_light.svg';
import { SignUpForm } from "@src/sections/auth/signUp";
import LoginCarousel from "@components/login_carousel/LoginCarousel";

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'flex-end',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 760,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  background: 'linear-gradient(180deg, #232056 0%, #4B46DE 154.23%)',
  borderRadius: '0',
}));

const LogoStyle = styled('div')(({ theme }) => ({
  width: 132,
  marginTop: 40,
  marginLeft: 100,
  marginRight: 'auto',
  marginBottom: 228,
}));

const VectorStyle = styled('div')(({ theme }) => ({
  height: 641,
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function SignUp() {
  const { method } = useAuth();

  const smUp = useResponsive('up', 'sm');

  const mdUp = useResponsive('up', 'md');

  return (
    <Page title="Login">
      <RootStyle>
        <HeaderStyle>
          {smUp && (
            <Typography variant="body2" sx={{ mt: { md: -2 } }}>
              Already have an account?  {''}
              <Link variant="subtitle2" component={RouterLink} to={PATH_AUTH.login}>
                Login
              </Link>
            </Typography>
          )}
        </HeaderStyle>

        {mdUp && (
          <SectionStyle>
            <LogoStyle>
              <img src={LogoLight} alt="Logo"/>
            </LogoStyle>
            <VectorStyle>
              <img src={Vector} alt="Waves"/>
            </VectorStyle>
            <LoginCarousel/>
          </SectionStyle>
        )}

        <Container maxWidth="sm">
          <ContentStyle>
            <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="h4" gutterBottom>
                  Get started absolutely free.
                </Typography>
                <Typography sx={{ color: 'text.secondary' }}>Free forever. No credit card needed.</Typography>
              </Box>
            </Stack>

            <SignUpForm />

            {!smUp && (
              <Typography variant="body2" align="center" sx={{ mt: 3 }}>
                Don’t have an account?{' '}
                <Link variant="subtitle2" component={RouterLink} to={PATH_AUTH.register}>
                  Get started
                </Link>
              </Typography>
            )}
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
