// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Stack } from '@mui/material';
// hooks
import useSettings from '../hooks/useSettings';
// _mock_
import { _bankingContacts, _bankingCreditCard, _bankingRecentTransitions } from '../_mock';
// components
import Page from '../components/Page';
// sections
import {
  BankingContacts,
  BankingWidgetSummary,
  BankingInviteFriends,
  BankingQuickTransfer,
  BankingCurrentBalance,
  BankingBalanceStatistics,
  BankingRecentTransitions,
  BankingExpensesCategories,
} from '../sections/@dashboard/general/banking';
import { BenInviteUser } from "@src/sections/@dashboard/general/app";
import { useDispatch, useSelector } from "@redux/store";
import { useEffect } from "react";
import { getTotalCampaignRevenue, getTotalCashBack, getTotalCustomers } from "@redux/slices/dashboard";

// ----------------------------------------------------------------------

export default function BenWalletPage() {
  const theme = useTheme();

  const { totalCashback, totalCampaignRevenue } = useSelector((state) => state.dashboard);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getTotalCashBack())
    dispatch(getTotalCampaignRevenue())
  } , [])

  const { themeStretch } = useSettings();

  return (
    <Page title="Wallet">
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Grid container spacing={3}>
          <Grid alignSelf={'flex-end'} item xs={12} md={7}>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={3}>
              <BankingWidgetSummary
                title="Campaign revenue"
                icon={'eva:diagonal-arrow-left-down-fill'}
                percent={totalCampaignRevenue}
                total={totalCampaignRevenue}
                chartData={[111, 136, 76, 108, 74, 54, 57, 84]}
              />

              <BankingWidgetSummary
                title="Total cash back paid"
                color="warning"
                icon={'eva:diagonal-arrow-right-up-fill'}
                percent={totalCashback}
                total={totalCashback}
                chartData={[111, 136, 76, 108, 74, 54, 57, 84]}
              />
            </Stack>
          </Grid>

          <Grid item xs={12} md={5}>
            <BankingCurrentBalance list={_bankingCreditCard} />
          </Grid>

          {/*<Grid item xs={12} md={8}>
            <Stack spacing={3}>

              <BankingRecentTransitions
                title="Recent Transactions"
                tableData={_bankingRecentTransitions}
                tableLabels={[
                  { id: 'description', label: 'Description' },
                  { id: 'date', label: 'Date' },
                  { id: 'amount', label: 'Amount' },
                  { id: 'status', label: 'Status' },
                  { id: '' },
                ]}
              />
            </Stack>
          </Grid>*/}
        </Grid>
      </Container>
    </Page>
  );
}
