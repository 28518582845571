// ----------------------------------------------------------------------

import { pxToRem, responsiveFontSizes } from '../../utils/getFontValue';



export default function Button(theme) {
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius:"12px",
          textTransform: 'initial',
          '&:hover': {
            boxShadow: 'none',
          },
        },
        sizeLarge: {
          height: 48,
          fontSize: pxToRem(15),
          lineHeight: "26px",
        },
        sizeMedium: {
          height: 36,
          fontSize: pxToRem(14),
          lineHeight: "24px",
        },
        sizeSmall: {
          height: 30,
          fontSize: pxToRem(13),
          lineHeight: "22px",
        },
        // contained
        containedInherit: {
          background: theme.palette.gradients.purple,
          color: theme.palette.grey[0],
          boxShadow: theme.customShadows.z8,
          '&:hover': {
            backgroundColor: theme.palette.grey[800],
          },
        },
        containedPrimary: {
          boxShadow: theme.customShadows.primary,
        },
        containedSecondary: {
          boxShadow: theme.customShadows.secondary,
        },
        containedInfo: {
          boxShadow: theme.customShadows.info,
        },
        containedSuccess: {
          boxShadow: theme.customShadows.success,
        },
        containedWarning: {
          boxShadow: theme.customShadows.warning,
        },
        containedError: {
          boxShadow: theme.customShadows.error,
        },
        // outlined
        outlinedInherit: {
          border: `1px solid ${theme.palette.grey[500_32]}`,
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        },
        textInherit: {
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        },
      },
    },
  };
}
