import PropTypes from 'prop-types';
// @mui
import { Box } from '@mui/material';
// components
import { IconButtonAnimate } from '@components/animate';

// ----------------------------------------------------------------------

CollapseButton.propTypes = {
  collapseClick: PropTypes.bool,
  onToggleCollapse: PropTypes.func,
};

export default function CollapseButton({ onToggleCollapse, collapseClick }) {
  return (
    <IconButtonAnimate onClick={onToggleCollapse}>
      <Box
        sx={{
          lineHeight: 0,
          transition: (theme) =>
            theme.transitions.create('transform', {
              duration: theme.transitions.duration.shorter,
            }),
          ...(collapseClick && {
            transform: 'rotate(180deg)',
          }),
        }}
      >
        {icon}
      </Box>
    </IconButtonAnimate>
  );
}

// ----------------------------------------------------------------------

const icon = (
    <svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.53033 2.23028C7.82322 1.93739 7.82322 1.46251 7.53033 1.16962C7.23744 0.876728 6.76256 0.876728 6.46967 1.16962L0.46967 7.16962C0.176777 7.46251 0.176777 7.93739 0.46967 8.23028L6.46967 14.2303C6.76256 14.5232 7.23744 14.5232 7.53033 14.2303C7.82322 13.9374 7.82322 13.4625 7.53033 13.1696L2.06066 7.69995L7.53033 2.23028Z" fill="#95A1A7"/>
        <path d="M16.5303 2.23028C16.8232 1.93739 16.8232 1.46251 16.5303 1.16962C16.2374 0.876728 15.7626 0.876728 15.4697 1.16962L9.46967 7.16962C9.17678 7.46251 9.17678 7.93739 9.46967 8.23028L15.4697 14.2303C15.7626 14.5232 16.2374 14.5232 16.5303 14.2303C16.8232 13.9374 16.8232 13.4625 16.5303 13.1696L11.0607 7.69995L16.5303 2.23028Z" fill="#95A1A7"/>
    </svg>
);
