// utils
import createAvatar from '../utils/createAvatar';
//
import Avatar from './Avatar';
import { useEffect } from "react";
import { getUserInfo } from "@redux/slices/account";
import { dispatch } from "@redux/store";
//
import AccountSvg from '@assets/icon/shop.svg'

// ----------------------------------------------------------------------

export default function MyAvatar({ user, ...other }) {
  return (
    <Avatar
      sx={{
        '& .MuiAvatar-img': { width: 24, height: 24, borderRadius: '50%' },
      }}
      src={user?.logoUrl || AccountSvg}
      alt={user?.displayName}
      /*color={user?.logoUrl ? 'default' : createAvatar(user?.displayName).color}*/
      {...other}
    >
      {createAvatar(user?.displayName).name}
    </Avatar>
  );
}
