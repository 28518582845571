import PropTypes from 'prop-types';
import { Stack, InputAdornment, TextField, MenuItem, Typography } from '@mui/material';
import { alpha, styled } from "@mui/material/styles";
import Iconify from '@components/Iconify';
import { getLocationFullAddress } from "@utils/getLocationFullAddress";
// components

// ----------------------------------------------------------------------

BenCampaignTableToolbar.propTypes = {
  filterName: PropTypes.string,
  filterLocation: PropTypes.string,
  onFilterName: PropTypes.func,
  onFilterLocationChange: PropTypes.func,
  optionsLocation: PropTypes.arrayOf(PropTypes.any),
};

const HeaderToolbar = styled('div')(({theme}) => ({
  display: 'flex',
  [theme.breakpoints.up('xs')]: {
    flexDirection: 'column',
    px: 10,
  },
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
  },
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
}));

export default function BenCampaignTableToolbar({active, onFilterName, filterName, filterLocation, onFilterLocationChange, optionsLocation}) {
  return (
    <Stack spacing={2} direction={{xs: 'column', sm: 'row'}} sx={{py: 2.5, px: 3}}>
      <HeaderToolbar>
        <Typography
          variant="subtitle2"
          sx={{
            textAlign: "start",
            fontSize: 18,
            mb: [4, 0],
          }}
        >
          {`${active ? 'Active' : ''} Campaigns`}
        </Typography>
        {
          active ? (
            <TextField
              fullWidth
              select
              label="Location"
              value={filterLocation}
              onChange={onFilterLocationChange}
              SelectProps={{
                MenuProps: {
                  sx: {'& .MuiPaper-root': {maxHeight: 260}},
                },
              }}
              sx={{
                maxWidth: {sm: 240},
                textTransform: 'capitalize',
              }}
            >
              <MenuItem
                value={ '' }
                sx={{
                  mx: 1,
                  my: 0.5,
                  borderRadius: 0.75,
                  typography: 'body2',
                  textTransform: 'capitalize',
                }}
              >
                All
              </MenuItem>
              {optionsLocation?.map((option) => (
                <MenuItem
                  key={option.id}
                  value={ option.id }
                  sx={{
                    mx: 1,
                    my: 0.5,
                    borderRadius: 0.75,
                    typography: 'body2',
                    textTransform: 'capitalize',
                  }}
                >
                  { getLocationFullAddress(option) }
                </MenuItem>
              ))}
            </TextField>
          ) : (
            <TextField
              sx={{
                width: '100%',
                maxWidth: '350px',
              }}
              value={filterName}
              onChange={(event) => onFilterName(event.target.value)}
              placeholder="Search..."
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Iconify icon={'eva:search-fill'} sx={{color: 'text.disabled', width: 20, height: 20}}/>
                  </InputAdornment>
                ),
              }}
            />
          )
        }

      </HeaderToolbar>


    </Stack>
  );
}
