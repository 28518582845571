import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
// import mailReducer from './slices/mail';
// import chatReducer from './slices/chat';
// import calendarReducer from './slices/calendar';
// import kanbanReducer from './slices/kanban';
import productReducer from './slices/product';
import dashboardReducer from './slices/dashboard';
import accountReducer from './slices/account';
import campaignsReducer from './slices/campaigns';
import billingReducer from './slices/billing';
import walletReducer from './slices/wallet';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

const rootReducer = combineReducers({
  // mail: mailReducer,
  // chat: chatReducer,
  // calendar: calendarReducer,
  // kanban: kanbanReducer,
  dashboard: dashboardReducer,
  account: accountReducer,
  campaigns: campaignsReducer,
  product: persistReducer(productPersistConfig, productReducer),
  billing: billingReducer,
  wallet: walletReducer,
});

export { rootPersistConfig, rootReducer };
