import { memo } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

function UploadIcon({ ...other }) {
  const theme = useTheme();

  const PRIMARY_MAIN = theme.palette.primary.main;

  const PRIMARY_DARK = theme.palette.primary.dark;

  return (
    <Box {...other}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.48" d="M15.6343 12.5157L13.1693 10.0004C13.1682 9.99921 13.1671 9.9982 13.166 9.99702C13.1638 9.99467 13.1614 9.99232 13.1591 9.98997C13.1574 9.98846 13.1559 9.98679 13.1544 9.98528C13.1522 9.98309 13.15 9.98108 13.1478 9.9789C13.1465 9.97772 13.1453 9.97655 13.1442 9.97537C12.916 9.75533 12.6059 9.61987 12.264 9.61987C11.9137 9.61987 11.5968 9.76187 11.3673 9.99149L8.83217 12.5266C8.33719 13.0216 8.33719 13.8243 8.83217 14.3194C9.07975 14.567 9.40419 14.6907 9.72864 14.6907C10.0529 14.6907 10.3774 14.567 10.6249 14.3194L10.9104 14.0339C10.9422 14.0022 10.9962 14.0247 10.9962 14.0695V19.0272C10.9962 19.7378 11.5778 20.3365 12.2883 20.3231C12.9771 20.31 13.5315 19.7476 13.5315 19.0557V14.1155C13.5315 14.0705 13.5863 14.0482 13.6178 14.0802L13.8432 14.3103C14.0915 14.5636 14.42 14.6907 14.7486 14.6907C15.0772 14.6907 15.4061 14.5638 15.6551 14.309C16.1407 13.8129 16.1202 13.0114 15.6343 12.5157V12.5157Z" fill="#637381"/>
        <path d="M19.4749 8.59164C19.274 5.47456 16.6749 3 13.5086 3C12.9354 3 12.3667 3.08157 11.8184 3.24237C10.3857 3.66316 9.1597 4.61435 8.39113 5.88612C7.946 5.66457 7.45622 5.54892 6.95067 5.54892C5.31718 5.54892 3.9593 6.75356 3.73959 8.34122C2.967 8.70209 2.30904 9.26169 1.82497 9.97252C1.28534 10.7653 1 11.6904 1 12.648C1 15.2343 3.0244 17.3217 5.6089 17.3999L9.92323 17.4004V15.7572C9.85912 15.7624 9.79449 15.7649 9.72971 15.7649C9.10414 15.7649 8.51601 15.5214 8.07373 15.0789C7.16064 14.166 7.16064 12.6802 8.07373 11.7671L10.6089 9.23198C11.051 8.78954 11.6393 8.54582 12.265 8.54582C12.875 8.54582 13.4525 8.77896 13.8913 9.20261C13.8921 9.20345 13.8983 9.20932 13.9047 9.2157L16.4026 11.7639C17.2984 12.6779 17.3082 14.1568 16.4243 15.0603C15.9796 15.5148 15.385 15.7649 14.7498 15.7649C14.702 15.7649 14.6543 15.7636 14.607 15.7607V17.4006L18.707 17.3997C21.1142 17.3136 23 15.3584 23 12.9484C23 10.83 21.51 9.02234 19.4749 8.59164Z" fill="#637381"/>
      </svg>
    </Box>
  );
}

export default memo(UploadIcon);
