import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { TextField } from '@mui/material';
//
import InputMask from "react-input-mask";
// ----------------------------------------------------------------------

RHFMaskedInput.propTypes = {
  name: PropTypes.string,
  mask: PropTypes.string,
  maskChar: PropTypes.string,
};

export default function RHFMaskedInput({ mask, maskChar, name, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <InputMask
          mask={mask}
          maskChar={maskChar}
          value={field.value}
          onChange={field.onChange}
        >
          {(inputProps) => (
            <TextField {...inputProps} fullWidth error={!!error} helperText={error?.message} {...other} />
          )}
        </InputMask>
      )}
    />
  );
}
