import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';
import { generateUrlQueryParams } from "@utils/generateUrlQueryParams";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  invoices: {
    total: 0,
    limit: 0,
    start: 0,
    results: []
  },
};

const slice = createSlice({
  name: 'billing',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    setInvoices: (state, action) => {
      state.invoices.results = action.payload
      state.isLoading = false;
    },
  }
});

// Reducer
export default slice.reducer;

const endpoint = '/billing'

// Actions
export const {
  startLoading,
  hasError,
  setInvoices,
} = slice.actions;

// ----------------------------------------------------------------------

export function getInvoiceHistory(
  {
    limit = 100,
    start = 0,
    search
  }
) {
  return async () => {
    dispatch(startLoading());
    try {
      const url = generateUrlQueryParams(endpoint, {
        limit,
        start,
        search,
      })
      const response = await axios.get(url);
      dispatch(setInvoices(response.data))
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}
