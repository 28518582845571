// @mui
import { Box, Typography, Stack } from '@mui/material';
// assets
import UploadIllustration from '../../assets/illustration_upload.svg';

// ----------------------------------------------------------------------

const getContent = (csv) => {
  return (
    <Typography variant="body2" sx={{ color: '#637381', textAlign: 'center',  }}>
      Drop files here or click&nbsp;
      <br/>
      <Typography
        variant="body2"
        component="span"
        sx={{ color: 'green.500', textDecoration: 'underline' }}
      >
        browse
      </Typography>
      &nbsp; to select {csv ? 'a csv file ()' : 'a file in jpg format'}
    </Typography>
  )
}
export default function BlockContentCommon({ csv = '', simple = false }) {
  return (
    <Stack
      spacing={2}
      alignItems="center"
      justifyContent="center"
      direction={{ xs: 'column', sm: 'column', md: 'column' }}
      sx={{ width: 1, textAlign: { xs: 'center', md: 'left' } }}
    >
      {
        !csv && !simple && (
          <UploadIllustration sx={{ width: 220 }} />
        )
      }

      {
        csv && !simple && (
          <img src={UploadIllustration} alt="upload" style={{ width: 220 }}/>
        )
      }


      <Box sx={{ p: 3 }}>
        { getContent(csv) }
      </Box>
    </Stack>
  );
}
