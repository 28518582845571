export const LOCAL_STORAGE_KEY = {
  REGISTER_EMAIL: 'registerEmail',
  EMAIL_RECOVERY: 'email-recovery',
  EIN_DOCUMENT: 'ein_document',
  CLOVER_EMAIL: 'clover-email',
  CLOVER_ACCESS_TOKEN: 'clover-access-token',
  CLOVER_EMPLOYEE_ID: 'clover-employee-id',
  CLOVER_MERCHANT_ID: 'clover-merchant-id',
}

export const SESSION_STORAGE_KEY = {
  EIN_DOCUMENT: 'ein_document',
  EIN_DOCUMENT_NAME: 'ein_document_name',
}

export const COOKIE_KEY = {
  WELCOME_DIALOG_IS_SHOWED: 'ss_welcome_dialog_is_showed',
}

export const QUERY_KEY = {
  CREATE_CAMPAIGN_DRAWER: 'create_campaign_drawer',
  TAB_ACTIVE: 'tab_active',
}

export const USER_ACCOUNT_TAB = {
  GENERAL: 'general',
  LOCATIONS: 'locations',
  BILLING: 'billing',
  CHANGE_PASSWORD: 'change_password',
}

export const SPECIAL_CHARACTERS_REGEX = /(?=.*[!@#$%^&*])/;
export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;
export const PHONE_NUMBER_REGEX = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/;



export const DEFAULT_TABLE_PAGINATION_ROWS = [5, 10, 25, 50, 100];


export const CHANGE_LOGO_TEXT = 'you can change your logo in your account';
export const CHANGE_BANNER_TEXT = 'you can change your company banner in your account page';
export const CHANGE_WEBSITE_TEXT = 'you can change your website url in your account page';
