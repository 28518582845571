import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  balanceDue: {},
};

const slice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    setBalanceDue: (state, action) => {
      state.balanceDue = action.payload
      state.isLoading = false;
    },
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  startLoading,
  hasError,
  setBalanceDue,
} = slice.actions;

// ----------------------------------------------------------------------

export function getBalanceDue() {
  return async () => {
    dispatch(startLoading());
    try {
      const response = await axios.get(`/balance/totalCampaignBalance`);
      dispatch(setBalanceDue(
          {
            ...response.data,
          }
      ))
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}
