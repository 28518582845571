import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Button, IconButton, ListItem, ListItemText } from "@mui/material";
//
import Image from '../Image';
import RejectionFiles from './RejectionFiles';
import BlockContent from './BlockContent';
import SingleFilePreview from "@components/upload/SingleFilePreview";
import BlockContentCommon from "@components/upload/BlockContentCommon";
import { UploadIcon } from "@src/assets";
import { m } from "framer-motion";
import { varFade } from "@components/animate";
import Iconify from "@components/Iconify";
import { fData } from "@utils/formatNumber";
import getFileData from "@utils/getFileData";

// ----------------------------------------------------------------------

const DropZoneStyle = styled('div')(({ theme }) => ({
  outline: 'none',
  overflow: 'hidden',
  position: 'relative',
  padding: theme.spacing(5, 1),
  borderRadius: theme.shape.borderRadius,
  transition: theme.transitions.create('padding'),
  backgroundColor: theme.palette.background.neutral,
  border: `1px dashed ${theme.palette.grey[500_32]}`,
  '&:hover': { opacity: 0.72, cursor: 'pointer' },
}));

// ----------------------------------------------------------------------

UploadSingleFile.propTypes = {
  error: PropTypes.bool,
  showPreview: PropTypes.bool,
  csv: PropTypes.bool,
  simple: PropTypes.bool,
  onRemove: PropTypes.func,
  file: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  helperText: PropTypes.node,
  sx: PropTypes.object,
};

export default function UploadSingleFile(
  {
    error = false,
    showPreview = false,
    onRemove,
    file,
    helperText,
    csv = false,
    simple = false,
    sx,
    isInput,
    placeholder,
    label,
      errorBorder,
    ...other
  }) {

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    fileRejections
  } = useDropzone({ multiple: false, ...other });

  const { key, name, size, preview } = getFileData(file, 0);

  return (
    <Box sx={{ width: '100%', ...sx }}>
      {isInput ? (
        <Button
          variant="outlined"
          component="label"
          size="large"
          endIcon={!preview && <UploadIcon />}
          style={{
            color: '#8895A1',
            borderColor: errorBorder || '#dde1e3',
            position: 'relative',
            fontSize: '0.85rem',
            display: 'flax',
            alignItems: 'center',
            height: '56px',
            width: '232px'
          }}
        >

          {preview ? (
            <ListItem
              key={key}
              component={m.div}
              {...varFade().inRight}
              sx={{
                px: 0,
                py: 0,
              }}
            >
              <Iconify icon={'eva:file-fill'} sx={{ width: 28, height: 28, color: '#212B36', mr: 2 }} />

              <ListItemText
                primary={typeof file === 'string' ? file : name}
                secondary={typeof file === 'string' ? '' : fData(size || 0)}
                primaryTypographyProps={{ variant: 'subtitle2' }}
                secondaryTypographyProps={{ variant: 'caption' }}
              />

              {onRemove && (
                <IconButton edge="end" size="small" onClick={() => onRemove(file)}>
                  <Iconify icon={'eva:close-fill'} />
                </IconButton>
              )}
            </ListItem>
          ) : (
            <>
              <span
                style={{
                  fontSize: '1rem',
                  color: '#8895A1',
                  background: '#fff',
                  position: 'absolute',
                  top: '-12px',
                  left: '10px',
                  paddingLeft: '2px',
                  paddingRight: '2px',
                }}
              >
                {label}
              </span>

              {placeholder}
            </>
          )}

          <input {...getInputProps()} />

        </Button>
      ) : (
        <DropZoneStyle
          {...getRootProps()}
          sx={{
            ...(isDragActive && { opacity: 0.72 }),
            ...((isDragReject || error) && {
              color: 'error.main',
              borderColor: 'error.light',
              bgcolor: 'error.lighter',
            }),
            // ...(file && {
            //   padding: '12% 0',
            // }),
          }}
        >
          <input {...getInputProps()} />

          <BlockContentCommon csv={csv} simple={simple}/>

          {file && file?.type?.includes('image') && (
            <Image
              alt="file preview"
              src={typeof file === 'string' ? file : file.preview}
              sx={{
                top: 8,
                left: 8,
                borderRadius: 1,
                position: 'absolute',
                width: 'calc(100% - 16px)',
                height: 'calc(100% - 16px)',
              }}
            />
          )}

        </DropZoneStyle>
      )}

      {
        !isInput && file && !file?.type?.includes('image') && (
          <SingleFilePreview file={file} showPreview={showPreview} onRemove={onRemove} />
        )
      }

      {fileRejections.length > 0 && <RejectionFiles fileRejections={fileRejections} />}

      {helperText && helperText}
    </Box>
  );
}
