import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { FormControl, FormHelperText, InputLabel, Select, TextField } from '@mui/material';

// ----------------------------------------------------------------------

RHFSelect.propTypes = {
  children: PropTypes.node,
  name: PropTypes.string,
};

export default function RHFSelect({ name, label, children, renderValue, onOpen, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <FormControl
          {...other}
        >
          <InputLabel>{ label }</InputLabel>
          <Select
            {...field}
            select
            fullWidth
            error={!!error}
            label={label}
            helperText={error?.message}
            renderValue={renderValue}
            onOpen={onOpen}
            {...other}
          >
            {children}
          </Select>
          {
            !!error && (
            <FormHelperText error sx={{ px: 2 }}> { error?.message }</FormHelperText>
            )
          }
        </FormControl>
      )}
    />
  );
}
