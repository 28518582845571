import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';
import { generateUrlQueryParams } from "@utils/generateUrlQueryParams";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  customers:  {
    total: 0,
    limit: 0,
    start: 0,
    results: []
  },
  newCustomers: {},
  totalCustomers: {},
  totalCampaignRevenue: {},
  totalCashback: {},
  campaigns: {
    total: 0,
    limit: 0,
    start: 0,
    results: []
  },
};

const slice = createSlice({
  name: 'customers',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    setCustomers: (state, action) => {
      state.customers = action.payload
      state.isLoading = false;
    },

    setNewCustomers: (state, action) => {
      state.newCustomers = action.payload
      state.isLoading = false;
    },

    setTotalCustomers: (state, action) => {
      state.totalCustomers = action.payload
      state.isLoading = false;
    },

    setTotalCampaignRevenue: (state, action) => {
      state.totalCampaignRevenue = action.payload
      state.isLoading = false;
    },

    setTotalCashback: (state, action) => {
      state.totalCashback = action.payload
      state.isLoading = false;
    },

    setCampaigns: (state, action) => {
      state.campaigns = action.payload
      state.isLoading = false;
    },
    deleteCampaigns: (state, action) => {
      state.isLoading = false;
    }

  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  startLoading, hasError,
  setCustomers, setNewCustomers, setTotalCustomers, setCampaigns,
  setTotalCampaignRevenue, setTotalCashback
} = slice.actions;

// ----------------------------------------------------------------------

export function getCustomersList(
  {
    limit = 100,
    start = 0,
    search
  }
) {
  return async () => {
    dispatch(startLoading());
    try {
      const url = generateUrlQueryParams('/customers/listOfCustomers', {
        limit,
        start,
        search,
      })
      const response = await axios.get(url);
      dispatch(setCustomers(response.data))
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function getNewCustomers() {
  return async () => {
    dispatch(startLoading());
    try {
      const response = await axios.get('/customers/newCustomers');
      dispatch(setNewCustomers(response.data))
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function getTotalCustomers() {
  return async () => {
    dispatch(startLoading());
    try {
      const response = await axios.get('/customers/totalCustomers');
      dispatch(setTotalCustomers(response.data))
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}


export function getTotalCampaignRevenue() {
  return async () => {
    dispatch(startLoading());
    try {
      const response = await axios.get('/revenue/totalCampaignRevenue');
      dispatch(setTotalCampaignRevenue(response.data))
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}


export function getTotalCashBack() {
  return async () => {
    dispatch(startLoading());
    try {
      const response = await axios.get('/cashback/totalCashback');
      dispatch(setTotalCashback(response.data))
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function getCampaigns(
  {
    limit = 100,
    start = 0,
    active = false,
    locationId = '',
    search = ''
  }
) {
  return async () => {
    dispatch(startLoading());
    try {
      const url = generateUrlQueryParams('/campaigns', {
        limit,
        start,
        active,
        location: locationId,
        search,
      })
      const response = await axios.get(url);
      dispatch(setCampaigns(response.data))
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function deleteCampaigns(id){
  return async () => {
    dispatch(startLoading());
    try {
      const response = await axios.delete(`/campaigns/${id}` );
      if (response?.data?.status === 'OK') dispatch(deleteCampaigns())
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}