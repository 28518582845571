const LOGO_REQS = {
  MIN_WIDTH: 200,
  MIN_HEIGHT: 200,
  MAX_WIDTH: 1000,
  MAX_HEIGHT: 1000,
  RATIO: 1 / 1,
}
const BANNER_REQS = {
  MIN_WIDTH: 560,
  MIN_HEIGHT: 400,
  MAX_WIDTH: 1000,
  MAX_HEIGHT: 714,
}


export const LOGO_REQ_TEXT =
`Allowed *.jpeg 1:1 ratio
(min. 200*200 px) (max. 1000*1000 px)
`
export const BANNER_REQ_TEXT =
`(min 560*400 px) (max 1000*714 px) 
background image with no text
`


export const companyBannerValidator = async (file) => {
  const imageWithParams = await getImageProps(file)
  return validateImage(imageWithParams, BANNER_REQS)
}

export const companyLogoValidator = async (file) => {
  const imageWithParams = await getImageProps(file)
  return validateImage(imageWithParams, LOGO_REQS);
}


const validateImage = (image, reqs) => {
  const {width, height} = image;
  const {
    MIN_WIDTH,
    MIN_HEIGHT,
    MAX_WIDTH,
    MAX_HEIGHT,
    RATIO
  } = reqs;
  if (
    width < MIN_WIDTH ||
    height < MIN_HEIGHT ||
    width > MAX_WIDTH ||
    height > MAX_HEIGHT ||
    (RATIO && RATIO !== width / height)
  ) {
    return {
      code: "error",
      message: `Invalid image dimensions`,
    }
  }
  return null;
}


export const getImageProps = async (file) => {
  return new Promise((resolve, reject) => {
    const image = new Image()
    image.onload = () => {
      file.width = image.width
      file.height = image.height
      resolve(file)
    }
    const url = URL.createObjectURL(file)
    image.src = url
  })
}