import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
};

const slice = createSlice({
  name: 'campaigns',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    createCampaign: (state, action) => {
      state.campaigns = action.payload
      state.isLoading = false;
    },

  }
});

// Reducer
export default slice.reducer;

// Actions

// ----------------------------------------------------------------------

export function postCreateCampaign(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/campaigns', data);
      dispatch(slice.actions.createCampaign(response.data))
      console.log(response.data)
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.error)
    }
  };
}