import * as Yup from 'yup';
import { Link as RouterLink, useNavigate, useSearchParams } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Link, Stack, Alert, Typography, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// hooks
import useIsMountedRef from '@hooks/useIsMountedRef';
// components
import { FormProvider, RHFTextField } from '@components/hook-form';

// routes
import { PATH_AUTH, PATH_PAGE } from '@routes/paths';
import { styled } from "@mui/material/styles";

// ----------------------------------------------------------------------

const FormStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
}));

const ButtonStyle = styled('div')(({ theme }) => ({
  marginTop: 24,
}));

const ContentStyle = styled('div')(({ theme }) => ({
  fontSize: 14,
  marginTop: 24,
  textAlign: 'center',
}));

export default function SignUpForm() {

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const isMountedRef = useIsMountedRef();

  const defaultValues = {
    referralCode: searchParams.get('referralCode') || ''
  };

  const LoginSchema = Yup.object().shape({
    firstName: Yup.string()
        .required('First name required')
        .min(2, 'Invalid First name')
        .max(255, 'Invalid First name')
    ,
    lastName: Yup.string()
        .required('Last name required')
        .min(2, 'Invalid Last name')
        .max(255, 'Invalid Last name'),
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    referralCode: Yup.string(),
  });

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    try {
      return navigate(PATH_AUTH.terms, {
        state: {
          registerData: data
        }
      })
    } catch (error) {
      console.error(error);
      reset();
      if (isMountedRef.current) {
        setError('afterSubmit', { ...error, message: error.message });
      }
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}

        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <RHFTextField name="firstName" label="First name*" />
          <RHFTextField name="lastName" label="Last name*" />
        </Stack>

        <RHFTextField name="email" label="Business email address*" />
        <RHFTextField name="referralCode" label="Referral code" />

      </Stack>

      <ButtonStyle>
        <LoadingButton color="inherit" fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
          Sign up
        </LoadingButton>
      </ButtonStyle>
      {/*<ContentStyle>
        <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography sx={{ color: 'text.secondary' }} variant="body2">
              By signing up, I agree to Benjamin{' '}
              <Link color="#002433" fontWeight={500} variant="subtitle2" href={PATH_PAGE.termsAndConditions}>
                Terms & Conditions
              </Link>
            </Typography>
          </Box>
        </Stack>
      </ContentStyle>*/}

    </FormProvider>
  );
}
