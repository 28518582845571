import axios from 'axios';
// config
import { HOST_API } from '../config';
import { PATH_AUTH } from "@routes/paths";
import { UserPool } from "@src/contexts/BenAWSCognitoContext";

// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  baseURL: HOST_API,
});

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const status = error?.response?.status;
    if (status === 401) {
      const user = UserPool.getCurrentUser();
      user.signOut();
      window.location.href = PATH_AUTH.login
    }
    return Promise.reject((error.response && error.response.data) || 'Something went wrong')
  }
);

export default axiosInstance;
