// @mui
import PropTypes from 'prop-types';
import { styled ,alpha} from '@mui/material/styles';
import { Typography, Card, CardContent } from '@mui/material';

// ----------------------------------------------------------------------
const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  //backgroundColor: "rgba(255,0,0,0.16)",
  background: "linear-gradient(0deg, rgba(57, 53, 160, 0.08), rgba(57, 53, 160, 0.08))",
[theme.breakpoints.up('md')]: {
    height: '100%',
    display: 'flex',
    textAlign: 'left',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

// ----------------------------------------------------------------------

BenAppWelcome.propTypes = {
  action: PropTypes.node,
  description: PropTypes.string,
  img: PropTypes.node,
  title: PropTypes.string,
};

export default function BenAppWelcome({companyName, title, description, action, img, ...other }) {
  return (
    <RootStyle {...other}>
      <CardContent
        sx={{
          p: { md: 0 },
          pl: { md: 5 },
          maxWidth: 340,
          color: 'grey.800',
        }}
      >
        <Typography gutterBottom variant="h5" sx={{ whiteSpace: 'pre-line' }}>
          Hey, {companyName}.
        </Typography>

        <Typography variant="body2" sx={{ pb: { xs: 3, xl: 5 }, maxWidth:400 ,mx: 'auto' }} >
          <span>You can start by </span>
          <span style={{ textDecoration: 'underline'}}>checking out this article</span> <br/>
          and then create new campaigns!
        </Typography>

        {action && action}
      </CardContent>

      {img && img}
    </RootStyle>
  );
}
