import { styled } from '@mui/material/styles';
import { Card, Stack, Typography, TextField, InputAdornment, Button } from '@mui/material';
import Iconify from '@components/Iconify';
import { useEffect } from "react";
import { useDispatch, useSelector } from "@redux/store";
import { getConfig } from "@redux/slices/account";
import { isEmpty } from "lodash";
import { createBranchIOLink } from "@utils/createBranchIOLink";

const RootStyle = styled(Card)(({theme}) => ({
  boxShadow: theme.customShadows.card,
  backgroundColor: '#F7F9FA',
}));
const InviteSection = styled('div')(({theme}) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  fontSize: 64,
  fontWeight: 600,
  marginBottom: 18,
}));

export default function BenInviteUser() {
  const { config } = useSelector((state) => state.account);
  const dispatch = useDispatch();
  const { customerInvitationLink, customersInvitationInfo, customersInvitationReward, customersInvitationTitle } = config;
  useEffect(() => {
    isEmpty(config) && dispatch(getConfig())
  } , [config])
  const onClickCopy = async () => {
    const branchIOLink = await createBranchIOLink(customerInvitationLink) || '';
    navigator.clipboard.writeText(branchIOLink)
  }
  if (isEmpty(config)) return null;
  return (
    <RootStyle
      sx={{
        padding: '30px 24px',
      }}
    >
      <Stack>
        <InviteSection>
          <Typography
            variant="subtitle2" sx={{
            textAlign: "start",
            fontSize: 24,
            lineHeight: '36px',
            maxWidth: 194,
            height: 72,
          }}
          >
            { customersInvitationTitle }
          </Typography>
        <span> { `$${customersInvitationReward}` } </span>
        </InviteSection>
        <Typography
          variant="subtitle2"
          sx={{
            fontSize: 14,
            height: 44,
            fontWeight: 500,
            marginBottom: 2,
            color: '#3D4852'
          }}
        > { customersInvitationInfo } </Typography>

        <Button
          variant="outlined"
          size="large"
          color="inherit"
          onClick={onClickCopy}
        >
          Copy link
        </Button>
      </Stack>
    </RootStyle>
  );
}
