import PropTypes from 'prop-types';
import { useState } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import { Avatar, Checkbox, TableRow, TableCell, Typography, MenuItem } from '@mui/material';
import { format } from 'date-fns';
// components
import Label from '@components/Label';
import Iconify from '@components/Iconify';
import { TableMoreMenu } from '@components/table';
import { getLocationFullAddress } from "@utils/getLocationFullAddress";

// ----------------------------------------------------------------------

BenCampaignTableRow.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
  onEditRow: PropTypes.func,
  onSelectRow: PropTypes.func,
  onDeleteRow: PropTypes.func,
};

export default function BenCampaignTableRow({ row, selected, onEditRow, onSelectRow, onDeleteRow }) {
  const theme = useTheme();

  const { name, locations, endDate, role, isVerified, status } = row;

  const [openMenu, setOpenMenuActions] = useState(null);

  const handleOpenMenu = (event) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };
  return (
    <TableRow hover selected={selected}>
      <TableCell padding="checkbox">
        <Checkbox checked={selected} onClick={onSelectRow} />
      </TableCell>

      <TableCell>
        {/*<Avatar alt={name} src={avatarUrl} sx={{ mr: 2 }} />*/}
        <Typography variant="subtitle2" noWrap>
          {name}
        </Typography>
      </TableCell>

      <TableCell align="left">
        {
          locations.map(location => (
            <div key={location.id}> { getLocationFullAddress(location)} </div>
          ))
        }
      </TableCell>

      <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
        { format(new Date(endDate), 'dd MMM yyyy') }
      </TableCell>

      {/*<TableCell align="left" sx={{ textTransform: 'capitalize' }}>*/}
      {/*    {role}*/}
      {/*</TableCell>*/}

      <TableCell align="left">
        <Label
          variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
          color={(status === 'Ended' && 'default' ) || 'success'}
          sx={{ textTransform: 'capitalize' }}
        >
          {status}
        </Label>
      </TableCell>

      {/*<TableCell align="right">
        <TableMoreMenu
          open={openMenu}
          onOpen={handleOpenMenu}
          onClose={handleCloseMenu}
          actions={
            <>
              <MenuItem
                onClick={() => {
                  onDeleteRow();
                  handleCloseMenu();
                }}
                sx={{ color: 'error.main' }}
              >
                <Iconify icon={'eva:trash-2-outline'} />
                Delete
              </MenuItem>
              <MenuItem
                onClick={() => {
                  onEditRow();
                  handleCloseMenu();
                }}
              >
                <Iconify icon={'eva:edit-fill'} />
                Edit
              </MenuItem>
            </>
          }
        />
      </TableCell>*/}
    </TableRow>
  );
}
