import { matchPath } from 'react-router-dom';

// ----------------------------------------------------------------------

export { default as NavSectionVertical } from './vertical';
export { default as NavSectionHorizontal } from './horizontal';

export function isExternalLink(path) {
  return path.includes('http');
}

export function getActive(path, pathname, withQuery) {
  if (withQuery && window) {
    const { search } = window.location;
    return path ? !!matchPath({ path, end: false }, `${pathname}${search}`) : false;
  }

  return path ? !!matchPath({ path, end: false }, pathname) : false;
}
